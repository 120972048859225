var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"addmerchant-page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"how-to-box"},[_c('div',{staticClass:"title-condition"},[_vm._v(" "+_vm._s(_vm.lbl['third-party-how-to'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v(" 1. "+_vm._s(_vm.lbl['third-party-how-to-detail-1'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v("2. "+_vm._s(_vm.detail_2))]),_c('div',{staticClass:"body-condition"},[_vm._v(" 3. "+_vm._s(_vm.lbl['third-party-how-to-detail-3'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v(" 4. "+_vm._s(_vm.lbl['third-party-how-to-detail-4'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v("5. "+_vm._s(_vm.detail_5))])])])]),_c('div',{staticClass:"row mt-3"}),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10"},[_c('a-input',{staticClass:"form-control w-100",attrs:{"type":"text","disabled":true,"placeholder":_vm.lbl['third-party-username-placeholder']},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_vm._m(0)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10"},[_c('a-input-password',{staticClass:"w-100",attrs:{"type":"password","placeholder":_vm.lbl['third-party-password-placeholder']},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_vm._m(1)]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.generateAPI}},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10"},[_c('a-form-item',{staticClass:"field-item"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'select_merchant',
              {
                initialValue: _vm.from.merchant,
                rules: [
                  {
                    required: true,
                    message: '',
                  } ],
              } ]),expression:"[\n              'select_merchant',\n              {\n                initialValue: from.merchant,\n                rules: [\n                  {\n                    required: true,\n                    message: '',\n                  },\n                ],\n              },\n            ]"}],staticClass:"w-100",attrs:{"size":"large","placeholder":this.lbl['third-party-select-merchant-placeholder']},on:{"change":function (e) {
                _vm.changeMerchant(e)
              }}},[_vm._l((_vm.optionMerchant),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" Merchant ID : "+_vm._s(item.name)+" ")])}),_vm._v(" "+_vm._s(_vm.optionMerchant)+" ")],2)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"lbl-title"},[_vm._v(" "+_vm._s(_vm.lbl['third-party-point-conversion-rate'])+" ")])]),_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10"},[_c('a-row',{staticClass:"mt-3"},[_c('a-col',{attrs:{"span":11}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'input_amount',
                  {
                    initialValue: _vm.from.amount,
                    rules: [
                      {
                        required: true,
                        message: '',
                      } ],
                  } ]),expression:"[\n                  'input_amount',\n                  {\n                    initialValue: from.amount,\n                    rules: [\n                      {\n                        required: true,\n                        message: '',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"max-length":10,"suffix":'| ' + _vm.lbl['third-party-thb'],"size":"large"},on:{"keypress":_vm.onlyNumber}})],1)],1),_c('a-col',{staticClass:"to-convert",attrs:{"span":2}},[_vm._v(" = ")]),_c('a-col',{attrs:{"span":11}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'input_point',
                  {
                    initialValue: _vm.from.point,
                  } ]),expression:"[\n                  'input_point',\n                  {\n                    initialValue: from.point,\n                  },\n                ]"}],attrs:{"disabled":true,"max-length":10,"suffix":'| ' + _vm.lbl['third-party-point'],"size":"large"}})],1)],1)],1)],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',[_c('a-button',{staticClass:"btn-default",attrs:{"type":"primary","block":"","html-type":"submit"}},[_c('img',{attrs:{"src":require("@/assets/images/thirdparty/attachment.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.lbl['third-party-generate-api'])+" ")])],1)],1)])])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-2 d-flex align-center pl-0"},[_c('img',{attrs:{"src":require("@/assets/images/thirdparty/check_account.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-2 d-flex align-center pl-0"},[_c('img',{attrs:{"src":require("@/assets/images/thirdparty/check_account.svg"),"alt":""}})])}]

export { render, staticRenderFns }