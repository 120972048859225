<template>
  <div v-if="show" class="addmerchant-page">
    <div class="row">
      <div class="col-10">
        <div class="how-to-box">
          <div class="title-condition">
            {{ lbl['third-party-how-to'] }}
          </div>
          <div class="body-condition">
            1. {{ lbl['third-party-how-to-detail-1'] }}
          </div>
          <div class="body-condition">2. {{ detail_2 }}</div>
          <div class="body-condition">
            3. {{ lbl['third-party-how-to-detail-3'] }}
          </div>
          <div class="body-condition">
            4. {{ lbl['third-party-how-to-detail-4'] }}
          </div>
          <div class="body-condition">5. {{ detail_5 }}</div>
        </div>
      </div>
    </div>

    <div class="row mt-3"></div>

    <!-- Username -->
    <div class="row mt-3">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10">
        <a-input
          v-model="username"
          type="text"
          class="form-control w-100"
          :disabled="true"
          :placeholder="lbl['third-party-username-placeholder']"
        />
      </div>
      <div class="col-2 d-flex align-center pl-0">
        <img src="@/assets/images/thirdparty/check_account.svg" alt="" />
      </div>
    </div>

    <!-- Pass -->
    <div class="row mt-4">
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10">
        <a-input-password
          v-model="password"
          type="password"
          class="w-100"
          :placeholder="lbl['third-party-password-placeholder']"
        />
      </div>
      <div class="col-2 d-flex align-center pl-0">
        <img src="@/assets/images/thirdparty/check_account.svg" alt="" />
      </div>
    </div>

    <a-form :form="form" @submit="generateAPI">
      <!-- Select -->
      <div class="row mt-4">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10">
          <a-form-item class="field-item">
            <a-select
              v-decorator="[
                'select_merchant',
                {
                  initialValue: from.merchant,
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                },
              ]"
              size="large"
              class="w-100"
              :placeholder="this.lbl['third-party-select-merchant-placeholder']"
              @change="
                e => {
                  changeMerchant(e)
                }
              "
            >
              <a-select-option
                v-for="(item, index) in optionMerchant"
                :key="index"
                :value="item.id"
              >
                Merchant ID : {{ item.name }}
              </a-select-option>
              {{ optionMerchant }}
            </a-select>
          </a-form-item>
        </div>
      </div>

      <!-- Input Point -->
      <div class="row">
        <div class="col-12">
          <div class="lbl-title">
            {{ lbl['third-party-point-conversion-rate'] }}
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-10 col-10">
          <a-row class="mt-3">
            <a-col :span="11">
              <a-form-item>
                <a-input
                  v-decorator="[
                    'input_amount',
                    {
                      initialValue: from.amount,
                      rules: [
                        {
                          required: true,
                          message: '',
                        },
                      ],
                    },
                  ]"
                  :max-length="10"
                  :suffix="'| ' + lbl['third-party-thb']"
                  size="large"
                  @keypress="onlyNumber"
                />
              </a-form-item>
            </a-col>
            <a-col :span="2" class="to-convert"> = </a-col>
            <a-col :span="11">
              <a-form-item>
                <a-input
                  v-decorator="[
                    'input_point',
                    {
                      initialValue: from.point,
                    },
                  ]"
                  :disabled="true"
                  :max-length="10"
                  :suffix="'| ' + lbl['third-party-point']"
                  size="large"
                /> </a-form-item
            ></a-col>
          </a-row>
        </div>
      </div>

      <!-- Button Generate API -->
      <div class="row mt-3">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item>
            <a-button
              type="primary"
              class="btn-default"
              block
              html-type="submit"
            >
              <img src="@/assets/images/thirdparty/attachment.svg" alt="" />
              {{ lbl['third-party-generate-api'] }}
            </a-button>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'
import BzbsSettings3rdParty from '@/core/Settings/3rdParty/BzbsSettings3rdParty'

export default {
  name: 'AddMerchant',
  mixins: [Mixin],
  components: {},
  props: {
    show: Boolean,
    data: Object,
  },
  data() {
    return {
      Partner: [],
      Account: [],
      languageLocale: 'en',
      detail_2: '',
      detail_5: '',
      username: '',
      password: '',
      isErrorDuplicate: false,
      isErrorIncorrect: false,
      optionMerchant: [],
      from: {
        merchant: undefined,
        amount: '',
        point: 1,
      },
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'add-merchant',
    })
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  watch: {
    data() {
      this.getData()
    },
  },

  methods: {
    init() {
      this.languageLocale = Locale.getLocaleCode()
      this.getData()
      this.getMerchantList()
      this.getTracePlanPoint()
    },
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    config: function () {
      return Vue.bzbsConfig
    },
    getData() {
      this.Partner = this.data.partner
      this.Account = this.data.account
      if (this.Partner != null) {
        this.detail_2 = this.lbl['third-party-how-to-detail-2'].replace(
          '{0}',
          this.Partner.name,
        )
        this.detail_5 = this.lbl['third-party-how-to-detail-5'].replace(
          '{0}',
          this.Partner.name,
        )
        this.getMerchantList()
        this.getTracePlanPoint()
      }
      if (this.Account != null) {
        this.username = this.Account.username
        this.password = this.Account.password
      }
    },
    getMerchantList() {
      return new Promise(resolve => {
        BzbsSettings3rdParty.getMerchantByPartnerId(this.Partner.id)
          .then(res => {
            console.log('getMerchantByPartnerId', res.data.success)
            if (res.data.success) {
              this.optionMerchant = res.data.data.merchants
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('getMerchantByPartnerId error', error)
            resolve(error)
          })
      })
    },
    getTracePlanPoint() {
      return new Promise(resolve => {
        BzbsSettings3rdParty.getTracePlanPoint(this.Partner.id)
          .then(res => {
            if (res.data.success) {
              this.from.amount = res.data.data.point
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('getTracePlanPoint error', error)
            resolve(error)
          })
      })
    },
    changeMerchant(e) {
      console.log('changeMerchant : ', e)
    },
    generateAPI(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('values : ', values)
          let params = {
            agencyId: Vue.$cookies.get('CRMUser').AgencyId
              ? Vue.$cookies.get('CRMUser').AgencyId
              : this.config().agency_id,
            partnerId: this.Partner.id,
            merchantId: values.select_merchant,
            username: this.username,
            password: this.password,
            point: values.input_amount,
          }

          console.log('params : ', params)

          return new Promise(resolve => {
            BzbsSettings3rdParty.postCreatePartnerAccount(params)
              .then(res => {
                if (res.data.success) {
                  this.$emit('changeMerchant', true)
                }
                resolve(res.data)
              })
              .catch(error => {
                console.log('postCreatePartnerAccount error', error)
                resolve(error)
              })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../styles/thirdparty_main.scss';

.addmerchant-page {
  .lbl-title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #212121;
  }

  .to-convert {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
  }

  .ant-input-password {
    cursor: not-allowed;

    .ant-input {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      opacity: 1;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .ant-input-password .ant-input:hover {
    border: 1px solid #ced4da;
  }
}
</style>
