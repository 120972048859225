<template>
  <div class="mx-auto mt-1 content-outer-container bg-none">
    <a-breadcrumb class="breadcrumb-3rdParty">
      <a-breadcrumb-item class="title"> {{ title }}</a-breadcrumb-item>
      <a-breadcrumb-item v-if="parent"
        ><router-link
          class="parent"
          v-if="parent.name"
          :to="{
            name: parent.name,
            params: parent.params,
            query: parent.query,
          }"
          >{{ parent.title }}</router-link
        ><span v-else class="parent"> {{ parent.title }} </span>
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ current ? current : title }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    title: String,
    parent: Object,
    current: String,
    breadcrumbs: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
@import '../styles/thirdparty_main.scss';

.bg-none {
  background-color: initial;
  box-shadow: initial;
}

.breadcrumb-3rdParty.ant-breadcrumb {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 18px 12px 18px 24px;
  margin-bottom: 16px;
  .ant-breadcrumb-separator {
    margin: 0 8px;
    color: #bdbdbd;
  }
  a {
    color: #bdbdbd;
  }
  span:last-child a {
    color: #bdbdbd;
  }
  span:last-child {
    color: #bdbdbd;
  }
  .title {
    color: #82b1ff;
  }
  .parent {
    color: #bdbdbd;
  }
}
</style>
