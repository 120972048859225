var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"account-page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"how-to-box"},[_c('div',{staticClass:"title-condition"},[_vm._v(" "+_vm._s(_vm.lbl['third-party-how-to'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v(" 1. "+_vm._s(_vm.lbl['third-party-how-to-detail-1'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v("2. "+_vm._s(_vm.detail_2))]),_c('div',{staticClass:"body-condition"},[_vm._v(" 3. "+_vm._s(_vm.lbl['third-party-how-to-detail-3'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v(" 4. "+_vm._s(_vm.lbl['third-party-how-to-detail-4'])+" ")]),_c('div',{staticClass:"body-condition"},[_vm._v("5. "+_vm._s(_vm.detail_5))])])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"},[(_vm.isErrorDuplicate)?_c('a-alert',{attrs:{"type":"error"}},[_c('span',{staticClass:"d-flex",attrs:{"slot":"description"},slot:"description"},[_c('img',{attrs:{"src":require("@/assets/images/thirdparty/error.svg"),"alt":""}}),_c('span',{staticClass:"lbl-error"},[_vm._v(_vm._s(_vm.lbl['third-party-error-duplicate']))])])]):_vm._e(),(_vm.isErrorIncorrect)?_c('a-alert',{attrs:{"type":"error"}},[_c('span',{staticClass:"d-flex",attrs:{"slot":"description"},slot:"description"},[_c('img',{attrs:{"src":require("@/assets/images/thirdparty/error.svg"),"alt":""}}),_c('span',{staticClass:"lbl-error"},[_vm._v(_vm._s(_vm.lbl['third-party-error-incorrect']))])])]):_vm._e()],1)]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.btnCreateAccount}},[_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: '',
                  } ],
              } ]),expression:"[\n              'username',\n              {\n                initialValue: '',\n                rules: [\n                  {\n                    required: true,\n                    message: '',\n                  },\n                ],\n              },\n            ]"}],staticClass:"form-control w-100",attrs:{"type":"text","placeholder":_vm.lbl['third-party-username-placeholder']}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: '',
                  } ],
              } ]),expression:"[\n              'password',\n              {\n                initialValue: '',\n                rules: [\n                  {\n                    required: true,\n                    message: '',\n                  },\n                ],\n              },\n            ]"}],staticClass:"w-100",attrs:{"type":"password","placeholder":_vm.lbl['third-party-password-placeholder']}})],1)],1)]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('a-button',{staticClass:"btn-default",class:{ 'disable-btn': _vm.isDisable },attrs:{"type":"primary","html-type":"submit","block":"","disabled":_vm.isDisable}},[_c('img',{attrs:{"src":require("@/assets/images/thirdparty/attachment.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.lbl['third-party-create-account'])+" ")])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }