<template>
  <div v-if="show" class="account-page">
    <div class="row">
      <div class="col-10">
        <div class="how-to-box">
          <div class="title-condition">
            {{ lbl['third-party-how-to'] }}
          </div>
          <div class="body-condition">
            1. {{ lbl['third-party-how-to-detail-1'] }}
          </div>
          <div class="body-condition">2. {{ detail_2 }}</div>
          <div class="body-condition">
            3. {{ lbl['third-party-how-to-detail-3'] }}
          </div>
          <div class="body-condition">
            4. {{ lbl['third-party-how-to-detail-4'] }}
          </div>
          <div class="body-condition">5. {{ detail_5 }}</div>
        </div>
      </div>
    </div>
    <!-- Error -->
    <div class="row mt-3">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
        <a-alert v-if="isErrorDuplicate" type="error">
          <span slot="description" class="d-flex">
            <img src="@/assets/images/thirdparty/error.svg" alt="" />
            <span class="lbl-error">{{
              lbl['third-party-error-duplicate']
            }}</span>
          </span>
        </a-alert>
        <a-alert v-if="isErrorIncorrect" type="error">
          <span slot="description" class="d-flex">
            <img src="@/assets/images/thirdparty/error.svg" alt="" />
            <span class="lbl-error">{{
              lbl['third-party-error-incorrect']
            }}</span>
          </span>
        </a-alert>
      </div>
    </div>
    <a-form :form="form" @submit="btnCreateAccount">
      <!-- Username -->
      <div class="row mt-3">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item>
            <a-input
              v-decorator="[
                'username',
                {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                },
              ]"
              type="text"
              class="form-control w-100"
              :placeholder="lbl['third-party-username-placeholder']"
            />
          </a-form-item>
        </div>
      </div>
      <!-- Pass -->
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-form-item>
            <a-input-password
              v-decorator="[
                'password',
                {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: '',
                    },
                  ],
                },
              ]"
              type="password"
              class="w-100"
              :placeholder="lbl['third-party-password-placeholder']"
            />
          </a-form-item>
        </div>
      </div>
      <!-- Button Create Account -->
      <div class="row mt-3">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-button
            type="primary"
            class="btn-default"
            html-type="submit"
            block
            :disabled="isDisable"
            :class="{ 'disable-btn': isDisable }"
          >
            <img src="@/assets/images/thirdparty/attachment.svg" alt="" />
            {{ lbl['third-party-create-account'] }}
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'
import BzbsSettings3rdParty from '@/core/Settings/3rdParty/BzbsSettings3rdParty'
import BzbsProfile from '@/core/Account/service/BzbsProfile'

export default {
  mixins: [Mixin],
  components: {},
  props: {
    show: Boolean,
    data: Object,
  },
  data() {
    return {
      Partner: [],
      languageLocale: 'en',
      detail_2: '',
      detail_5: '',
      isErrorDuplicate: false,
      isErrorIncorrect: false,
      loginType: null,
      appId: null,
      liffId: null,
      liffLanding: null,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'create-account',
    })
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  watch: {
    data() {
      this.getData()
    },
  },
  computed: {
    isDisable() {
      if (this.loginType == 'Line') {
        if (this.appId && (this.liffLanding || this.liffId)) {
          return false
        } else {
          return true
        }
      } else {
        if (this.appId) {
          return false
        } else {
          return true
        }
      }
    },
  },
  methods: {
    init() {
      this.languageLocale = Locale.getLocaleCode()
      this.isErrorDuplicate = false
      this.isErrorIncorrect = false
      this.getCRMPlusProfile()
      this.getData()
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            this.loginType = res.data.LoginType
            this.appId = res.data.AppId
            this.liffId = res.data.LiffId
            this.liffLanding = res.data.LiffLanding
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            resolve(error)
          })
      })
    },
    getData() {
      this.isErrorDuplicate = false
      this.isErrorIncorrect = false
      this.Partner = this.data.partner
      if (this.Partner != null) {
        this.detail_2 = this.lbl['third-party-how-to-detail-2'].replace(
          '{0}',
          this.Partner.name,
        )
        this.detail_5 = this.lbl['third-party-how-to-detail-5'].replace(
          '{0}',
          this.Partner.name,
        )
      }
    },
    btnCreateAccount(e) {
      e.preventDefault()
      Account.bzbsAnalyticTracking(
        'setting_api_page',
        'setting_api',
        'click_api_connect_create_account',
        'on click',
      )
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('values : ', values)
          return new Promise(resolve => {
            BzbsSettings3rdParty.getValidateUser(values.username)
              .then(res => {
                if (res.data.success) {
                  let data = {
                    username: values.username,
                    password: values.password,
                  }
                  this.$emit('changeAccount', data)
                } else {
                  this.isErrorDuplicate = true
                }
                resolve(res.data)
              })
              .catch(error => {
                console.log('getValidateUser error', error)
                this.isErrorDuplicate = true
                resolve(error)
              })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '../styles/thirdparty_main.scss';
.disable-btn {
  opacity: 0.5;
}
</style>
