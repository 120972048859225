<template>
  <div v-if="show" class="generate-page">
    <div class="content-generate mt-3">
      <div class="row">
        <div class="col-12">
          <div class="title">{{ lbl['third-party-point-rate'] }}</div>
        </div>
      </div>

      <!-- Username -->
      <div class="row mt-4">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-input
            v-model="username"
            type="text"
            class="form-control w-100"
            :disabled="true"
            :placeholder="lbl['third-party-username-placeholder']"
          />
        </div>
      </div>

      <!-- Pass -->
      <div class="row mt-4">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <a-input-password
            v-model="password"
            type="password"
            class="w-100"
            :placeholder="lbl['third-party-password-placeholder']"
          />
        </div>
        <div class="col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12">
          <a-button class="btn-outline" block @click="openUpdate">
            <span class="text-btn">{{
              lbl['third-party-update-password']
            }}</span>
          </a-button>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <a-row :gutter="16">
            <div
              v-for="(item, index) in merchants"
              :key="index"
              :value="item.id"
              class="column"
            >
              <a-col class="gutter-row mb-4" :span="12">
                <div
                  class="gutter-box box-generate"
                  :style="{
                    'min-height': max_height,
                  }"
                >
                  <div class="row d-flex h-100">
                    <div class="col-6">
                      <div class="title">
                        {{ lbl['third-party-point-rate'] }}
                      </div>
                    </div>
                    <div class="col-6 d-flex justify-end">
                      <a class="sw" @click="editMerchant(item.id)">
                        <img
                          :src="
                            require('@/assets/images/thirdparty/third_edit.svg')
                          "
                          alt=""
                        />
                        <span class="text-edit">
                          {{ lbl['third-party-edit'] }}
                        </span>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="row mh-rate align-center">
                        <div
                          :class="{ hide: item.id == edit_ID }"
                          class="col-12"
                        >
                          <div class="point-rate">
                            {{ item.point_rate }}
                            {{ lbl['third-party-thb'] }} &nbsp;&nbsp;&nbsp; =
                            &nbsp;&nbsp;&nbsp; 1 {{ lbl['third-party-point'] }}
                          </div>
                        </div>
                        <div
                          v-if="item.id == edit_ID"
                          class="
                            col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12
                            d-flex
                          "
                        >
                          <a-row>
                            <a-col :span="11">
                              <a-form-item
                                class="mb-0"
                                :validate-status="isErrorAmount"
                              >
                                <a-input
                                  v-model="item.point_rate"
                                  :max-length="10"
                                  :suffix="'| ' + lbl['third-party-thb']"
                                  size="large"
                                  @keypress="onlyNumber"
                                />
                              </a-form-item>
                            </a-col>
                            <a-col :span="2" class="to-convert"> = </a-col>
                            <a-col :span="11">
                              <a-form-item class="mb-0">
                                <a-input
                                  v-model="point"
                                  :disabled="true"
                                  :max-length="10"
                                  :suffix="'| ' + lbl['third-party-point']"
                                  size="large"
                                /> </a-form-item
                            ></a-col>
                          </a-row>
                        </div>
                        <div
                          v-if="item.id == edit_ID"
                          class="
                            col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12
                            d-flex
                          "
                        >
                          <a-button
                            type="primary"
                            class="btn-default h-40 mr-3"
                            @click="updatePointRate(item.id, item.point_rate)"
                          >
                            <span class="text-btn">{{
                              lbl['third-party-button-save']
                            }}</span>
                          </a-button>
                          <a-button
                            class="btn-outline mt-0"
                            @click="edit_ID = ''"
                          >
                            <span class="text-btn">{{
                              lbl['third-party-button-cancel']
                            }}</span>
                          </a-button>
                        </div>
                      </div>

                      <div class="box-generate bg-white">
                        <div class="mb-3">
                          {{ lbl['third-party-merchant-id'] }} :
                          <span class="font-bold">{{ item.id }}</span>
                        </div>
                        <div class="mb-3">
                          <span class="font-bold">{{
                            lbl['third-party-token']
                          }}</span>
                        </div>
                        <div class="box-generate">
                          <div class="row">
                            <div
                              class="
                                col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12
                                pb-2
                              "
                            >
                              <span class="font-token">{{ item.token }}</span>
                            </div>
                            <div
                              class="
                                col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12
                              "
                            >
                              <a-button
                                class="btn-outline"
                                block
                                @click="copyToken(item.token)"
                              >
                                <img
                                  class="text-btn"
                                  :src="
                                    require('@/assets/images/thirdparty/Duplicate.svg')
                                  "
                                  alt=""
                                />
                                <span class="text-btn">{{
                                  lbl['third-party-copy']
                                }}</span>
                              </a-button>
                            </div>
                          </div>
                        </div>

                        <div class="mt-2">
                          <a class="re" @click="reGenerateToken(item.id)">
                            <span class="text-edit">
                              {{ lbl['third-party-re-generate'] }}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </div>
            <div v-if="isAddMore" value="add-more" class="column">
              <a-col class="gutter-row mb-4" :span="12">
                <div
                  class="gutter-box box-generate"
                  :style="{ height: max_height }"
                >
                  <div class="row d-flex align-center justify-center h-100">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div class="text-center">
                        <a-button class="btn-outline" @click="addMerchant()">
                          <img
                            :src="
                              require('@/assets/images/thirdparty/add_circle.svg')
                            "
                            alt=""
                          />
                          <span class="btn-add-more">{{
                            lbl['third-party-add-more-merchant']
                          }}</span>
                        </a-button>
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </div>
          </a-row>
        </div>
      </div>
    </div>

    <!-- confirm modal -->
    <b-modal
      v-model="modalReToken"
      modal-class="modal-ReToken"
      :modalClass="[currentLocale]"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="modal-base">
        <div class="row mt-3">
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2">
            <img
              :src="require('@/assets/images/thirdparty/warning_amber.svg')"
              alt=""
            />
          </div>
          <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 col-10">
            <div class="modal-base-head">
              {{ lbl['third-party-generate-new-token'] }}
            </div>
            <div class="modal-base-body mt-2">
              {{ lbl['third-party-message-generate-new-token'] }}
            </div>
          </div>
        </div>
        <div class="modal-base-footer mt-4">
          <button class="btn btn-outline-secondary" @click="btnCancelReToken()">
            <span class="text-btn">{{
              lbl['third-party-btn-cancel-generate-new-token']
            }}</span>
          </button>
          <button class="btn btn-warning ml-2" @click="btnReToken()">
            <span class="text-btn">{{
              lbl['third-party-btn-submit-generate-new-token']
            }}</span>
          </button>
        </div>
      </div>
    </b-modal>

    <!-- update password modal -->
    <b-modal
      v-model="modalUpdatePassword"
      modal-class="modal-UpdatePassword"
      :modalClass="[currentLocale]"
      size="sm"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="modal-base">
        <div class="row mt-3">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="modal-base-head">
              {{ lbl['third-party-enter-your-new-password'] }}
            </div>
            <div class="modal-base-body mt-3">
              {{ brandName }}

              <div v-if="isErrorCannotPassword" class="row mt-3">
                <div class="col-12">
                  <a-alert type="error">
                    <span slot="description" class="d-flex">
                      <img src="@/assets/images/thirdparty/error.svg" alt="" />
                      <span class="lbl-error">{{
                        lbl['third-party-you-cannot-password']
                      }}</span>
                    </span>
                  </a-alert>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12">
                  <a-input
                    v-model="username"
                    type="text"
                    class="form-control w-100"
                    :disabled="true"
                    :placeholder="lbl['third-party-username-placeholder']"
                  />
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <a-form-item
                    class="mb-0"
                    :validate-status="isErrorCheckPassword"
                  >
                    <a-input-password
                      v-model="newpassword"
                      type="password"
                      class="w-100"
                      :placeholder="lbl['third-party-password-placeholder']"
                      @change="
                        e => {
                          checkPassword(e.target.value)
                        }
                      "
                    />
                  </a-form-item>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <b-button
                    variant="warning"
                    class="warning"
                    block
                    @click="btnUpdatePassword()"
                  >
                    {{ lbl['third-party-update-password'] }}
                  </b-button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12">
                  <b-button
                    variant="outline-warning"
                    class="outline-warning"
                    block
                    @click="btnCancelUpdatePassword()"
                  >
                    {{ lbl['third-party-btn-cancel-generate-new-token'] }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Locale from '@/helper/locale.js'
import BzbsSettings3rdParty from '@/core/Settings/3rdParty/BzbsSettings3rdParty'
import AlertModal from '@/components/Modal/Component/alertmodal2'

export default {
  mixins: [Mixin],
  props: {
    show: Boolean,
    data: Object,
  },
  components: {
    AlertModal,
  },
  data() {
    return {
      currentLocale: Locale.getLocaleShort(),
      Generate: {},
      Partner: {},
      languageLocale: 'en',
      username: '',
      password: '',
      point: 1,
      merchants: [],
      edit_ID: '',
      isErrorAmount: '',
      max_height: '',
      modalReToken: false,
      merchantId: '',
      modalUpdatePassword: false,
      brandName: '',
      newpassword: '',
      isErrorCannotPassword: false,
      isErrorCheckPassword: '',
      isAddMore: true,
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'create-generate',
    })
  },
  created() {
    this.handleFooter(true)
    this.init()
  },
  watch: {
    data() {
      this.getData()
    },
  },
  methods: {
    init() {
      this.languageLocale = Locale.getLocaleCode()
      this.getData()
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    config: function () {
      return Vue.bzbsConfig
    },
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    getData() {
      this.Partner = this.data.partner
      this.Generate = this.data.generate

      if (this.Generate != null) {
        this.username = this.Generate.username
        this.password = this.Generate.password
        this.merchants = this.Generate.merchants
      }

      if (this.Partner != null) {
        this.brandName = this.lbl['third-party-brand-account'].replace(
          '{0}',
          this.Partner.name,
        )
      }

      if (this.Partner != null && this.Generate != null) {
        if (
          this.Partner.merchants != undefined &&
          this.Partner.merchants != null &&
          this.Generate.merchants != undefined &&
          this.Generate.merchants != null
        ) {
          if (this.Partner.merchants.length == this.Generate.merchants.length) {
            this.isAddMore = false
          } else {
            this.isAddMore = true
          }
        }
      }

      setTimeout(() => this.genHeight(), 500)
    },
    genHeight() {
      var elements = document.getElementsByClassName('gutter-box')
      var max_height = ''
      for (var i = 0; i < elements.length; i++) {
        max_height = Math.max(
          max_height,
          document.getElementsByClassName('gutter-box').item(i).offsetHeight,
        )
      }
      this.max_height = max_height.toString() + 'px'
    },
    addMerchant() {
      let res = {
        add_merchant: true,
      }
      this.$emit('changeGenerate', res)
    },
    editMerchant(id) {
      console.log('editMerchant : ', id)
      this.edit_ID = id
      this.isErrorAmount = ''
    },
    updatePointRate(merchant_id, point) {
      if (point != null && point != '') {
        this.isErrorAmount = ''

        let obj = {
          partnerId: this.Partner.id,
          merchantId: merchant_id,
          username: this.Generate.username,
          point: point,
        }

        return new Promise(resolve => {
          BzbsSettings3rdParty.postUpdatePointRate(obj)
            .then(res => {
              if (res.data.success) {
                this.edit_ID = ''
                this.alertModalAction(
                  this.lbl['alert-box-success-header'],
                  this.lbl['third-party-message-success-point-rate'],
                  'success',
                  true,
                )
              } else {
                this.alertModalAction(
                  this.lbl['alert-box-error-header'],
                  this.lbl['third-party-message-error-point-rate'],
                  'error',
                  true,
                )
              }
              resolve(res.data)
            })
            .catch(error => {
              this.alertModalAction(
                this.lbl['alert-box-error-header'],
                this.lbl['third-party-message-error-point-rate'],
                'error',
                true,
              )
              resolve(error)
            })
        })
      } else {
        this.isErrorAmount = 'error'
      }
    },

    // Token
    copyToken(token) {
      console.log('copyToken : ', token)
      navigator.clipboard.writeText(token)
      this.$message.success({
        content: this.lbl['third-party-message-after-copy'],
        top: '100px',
        duration: 3,
        maxCount: 3,
      })
    },
    reGenerateToken(merchant_id) {
      this.merchantId = merchant_id
      this.modalReToken = true
    },
    btnCancelReToken() {
      this.merchantId = ''
      this.modalReToken = false
    },
    btnReToken() {
      let obj = {
        partnerId: this.Partner.id,
        merchantId: this.merchantId,
        username: this.Generate.username,
      }

      return new Promise(resolve => {
        BzbsSettings3rdParty.postReGenerateToken(obj)
          .then(res => {
            if (res.data.success) {
              this.merchantId = ''
              this.modalReToken = false
              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['third-party-message-success-generate-new-token'],
                'success',
                true,
              )
              let data = {
                re_generate_token: true,
              }

              this.$emit('changeGenerate', data)
            } else {
              this.merchantId = ''
              this.modalReToken = false
              this.alertModalAction(
                this.lbl['alert-box-error-header'],
                this.lbl['third-party-message-error-generate-new-token'],
                'error',
                true,
              )
            }
            resolve(res.data)
          })
          .catch(error => {
            this.merchantId = ''
            this.modalReToken = false
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              this.lbl['third-party-message-error-generate-new-token'],
              'error',
              true,
            )
            resolve(error)
          })
      })
    },

    // Update Password
    openUpdate() {
      this.isErrorCannotPassword = false
      this.isErrorCheckPassword = ''
      this.newpassword = ''
      this.modalUpdatePassword = true
    },
    checkPassword(e) {
      if (e != null) {
        this.isErrorCheckPassword = ''
      } else {
        this.isErrorCheckPassword = 'error'
      }
    },
    btnCancelUpdatePassword() {
      this.isErrorCannotPassword = false
      this.isErrorCheckPassword = ''
      this.newpassword = ''
      this.modalUpdatePassword = false
    },
    btnUpdatePassword() {
      if (this.newpassword != null && this.newpassword != '') {
        this.isErrorCheckPassword = ''
        let obj = {
          partnerId: this.Partner.id,
          merchantId: this.merchants[0].id,
          password: this.newpassword,
        }

        return new Promise(resolve => {
          BzbsSettings3rdParty.postUpdatePassword(obj)
            .then(res => {
              if (res.data.success) {
                this.isErrorCannotPassword = false
                this.isErrorCheckPassword = ''
                this.modalUpdatePassword = false
                this.newpassword = ''
                this.alertModalAction(
                  this.lbl['alert-box-success-header'],
                  this.lbl['third-party-message-update-password'],
                  'success',
                  true,
                )
                let data = {
                  update_password: true,
                }

                this.$emit('changeGenerate', data)
              } else {
                this.isErrorCannotPassword = true
              }
              resolve(res.data)
            })
            .catch(error => {
              this.isErrorCannotPassword = true
              resolve(error)
            })
        })
      } else {
        this.isErrorCheckPassword = 'error'
      }
    },
  },
}
</script>

<style lang="scss">
@import '../styles/thirdparty_main.scss';

.generate-page {
  .btn-add-more {
    letter-spacing: 1px;
    margin-left: 10px;
  }

  .hide {
    display: none;
  }

  .mh-rate {
    min-height: 80px;
  }

  .content-generate {
    gap: 24px;
    padding: 24px;
    border-radius: 4px;
    border: solid 1px #bdbdbd;
    background-color: #fafafa;
    .text-btn {
      vertical-align: text-bottom !important;
    }
  }

  .box-generate {
    gap: 24px;
    padding: 12px;
    border-radius: 4px;
    border: solid 1px #bdbdbd;
    background-color: #fafafa;

    .bg-white {
      background-color: white;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #424242;
  }

  .ant-input-password {
    cursor: not-allowed;

    .ant-input {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      opacity: 1;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  .ant-input-password .ant-input:hover {
    border: 1px solid #ced4da;
  }

  .text-edit {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1px;
    text-align: center;
    color: #2196f3;
  }

  .point-rate {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 0.15px;
    text-align: left;
    color: #616161;
  }

  .font-bold {
    font-weight: bold;
  }

  .font-token {
    word-break: break-all;
  }

  .to-convert {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
  }

  @media only screen and (max-device-width: 450px) and (orientation: portrait) {
    .btn-outline {
      margin-top: 2rem;
    }
  }

  @media only screen and (max-device-width: 1200px) {
    .ant-col-12 {
      width: 100%;
    }
  }
}

.modal-ReToken {
  .modal-body {
    padding: 1.5rem 2rem;

    .modal-base-head {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
    }

    .modal-base-body {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.15px;
      text-align: left;
      color: #616161;
    }

    .modal-base-footer {
      text-align: end;
    }

    .btn-warning {
      color: #ffffff;
      background-color: #ffc107;
      border-color: #ffc107;

      &:hover {
        color: #ffffff;
        background-color: #ecb100;
        border-color: #ecb100;
      }
    }
  }
}

.modal-UpdatePassword {
  .modal-body {
    padding: 1.5rem 2rem;

    .ant-alert-with-description.ant-alert-no-icon {
      padding: 5px 10px;
    }

    button.btn.warning {
      height: 45px;
      flex-grow: 0;
      display: flex;
      font-weight: 500;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 16px 24px;
      border-radius: 4px;
      background-color: #ff9800;
      border-color: #ff9800;
      font-size: 16px;

      &.h-40 {
        height: 40px;
      }

      &:hover {
        background-color: #ff9800;
        border-color: #ff9800;
      }

      &:active {
        background-color: #ff9800;
        border-color: #ff9800;
      }
    }

    button.btn.outline-warning {
      height: 45px;
      font-weight: 500;
      border-radius: 4px;
      background-color: #ffffff;
      border-color: #ff9800;
      color: #ff9800;
      font-size: 16px;

      &.h-40 {
        height: 40px;
      }

      &:hover {
        background-color: #ffffff;
        border-color: #ff9800;
      }

      &:active {
        background-color: #ffffff;
        border-color: #ff9800;
      }
    }

    .lbl-error {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.6px;
      text-align: left;
      color: #ff5252;
      margin-left: 10px;
      margin-right: 10px;
    }

    .form-control {
      height: 40px;
    }

    .modal-base-head {
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 0.15px;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
    }

    .modal-base-body {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 0.15px;
      text-align: left;
      color: #616161;
    }

    .modal-base-footer {
      text-align: end;
    }

    .btn-warning {
      color: #ffffff;
      background-color: #ffc107;
      border-color: #ffc107;

      &:hover {
        color: #ffffff;
        background-color: #ecb100;
        border-color: #ecb100;
      }
    }
  }

  .modal-sm {
    max-width: 350px;
  }
}
</style>
