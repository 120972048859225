import Vue from 'vue';
import BzbsApi from '@/core/Http/BzbsApi.js';
import Cache from '@/helper/Cache.js';

/**
 * @method getMerchantByPartnerId
 * @method getValidateUser
 * @method getTracePlanPoint
 * @method getMerchantDetail
 * @method postCreatePartnerAccount
 * @method postUpdatePointRate
 * @method postReGenerateToken
 * @method postUpdatePassword
 */

export default {
	KEY_COOKIE_USER: 'CRMUser',
	config: function() {
		return Vue.bzbsConfig;
	},
	token: function() {
		return Cache.get(this.KEY_COOKIE_USER).Token;
	},
	app_id: function() {
		return Cache.get(this.KEY_COOKIE_USER).AppId;
	},
	agency_id: function() {
		return Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
			? Vue.$cookies.get(this.KEY_COOKIE_USER).AgencyId
			: this.config().agency_id;
	},
	getMerchantByPartnerId: function(partner_id) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmpluspartner/pending_merchant' +
			'?agencyId=' +
			this.agency_id() +
			'&partnerId=' +
			partner_id;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},
	getValidateUser: function(username) {
		var strUrl = this.config().bzbsModuleUrl + '/crmpluspartner/validate_user' + '?username=' + username;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},
	getTracePlanPoint: function() {
		var strUrl = this.config().bzbsModuleUrl + '/crmpluspartner/trace_plan_point' + '?agencyId=' + this.agency_id();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},
	getMerchantDetail: function(partner_id) {
		var strUrl =
			this.config().bzbsModuleUrl +
			'/crmpluspartner/merchant_detail' +
			'?agencyId=' +
			this.agency_id() +
			'&partnerId=' +
			partner_id;
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},
	postCreatePartnerAccount: function(params) {
		var strUrl = this.config().bzbsModuleUrl + '/crmpluspartner/create_partner_account';
		return BzbsApi.connectBzbsPost(strUrl, this.token(), params);
	},
	postUpdatePointRate: function(obj) {
		let params = {
			partnerId: obj.partnerId,
			merchantId: obj.merchantId,
			username: obj.username,
			point: obj.point,
			agencyId: this.agency_id(),
			token: this.token()
		};
		var strUrl = this.config().bzbsModuleUrl + '/crmpluspartner/update_point_rate';
		return BzbsApi.connectBzbsPost(strUrl, this.token(), params);
	},
	postReGenerateToken: function(obj) {
		let params = {
			partnerId: obj.partnerId,
			merchantId: obj.merchantId,
			username: obj.username,
			agencyId: this.agency_id(),
			token: this.token()
		};
		var strUrl = this.config().bzbsModuleUrl + '/crmpluspartner/re_generate_partner_token';
		return BzbsApi.connectBzbsPost(strUrl, this.token(), params);
	},
	postUpdatePassword: function(obj) {
		let params = {
			agencyId: this.agency_id(),
			partnerId: obj.partnerId,
			merchantId: obj.merchantId,
			password: obj.password
		};
		var strUrl = this.config().bzbsModuleUrl + '/crmpluspartner/update_password';
		return BzbsApi.connectBzbsPost(strUrl, this.token(), params);
	}
};
