<template>
  <div class="container-fluid">
    <Header />
    <Breadcrumbs
      v-if="false"
      :title="currenttitle"
      :parent="parent"
      :current="current"
    />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="wrap-3rdParty 3rdParty-page">
        <div class="mx-auto content-inner-container border-header">
          <div class="3rdParty-page">
            <div class="col-12 title-header">
              <img
                src="@/assets/images/thirdparty/icon-exten.svg"
                class="mr-2"
                alt=""
              />
              <span>{{ lbl['extensions'] }}</span>
            </div>
          </div>
        </div>
        <a-row type="flex">
          <a-col flex="100px" class="partners">
            <a-list item-layout="horizontal" :data-source="configThirdParty">
              <a-list-item
                slot="renderItem"
                slot-scope="item"
                :class="{ active: item.id == partnerId }"
              >
                <a @click="handlePartner(item)">
                  <a-avatar shape="square" :size="80" :src="item.image_url" />
                </a>
              </a-list-item>
            </a-list>
          </a-col>
          <a-col flex="1 1 0" class="merchants">
            <div v-if="partnerId != ''" class="content">
              <div class="row">
                <div class="col-10">
                  <div class="title-partners">{{ Partner.name }}</div>
                  <div class="datail-partners">
                    <span v-if="languageLocale == 'en'">
                      {{ Partner.description_en }}
                    </span>
                    <span v-else>
                      {{ Partner.description_th }}
                    </span>
                  </div>
                </div>
                <div class="col-2">
                  <div
                    v-if="isBtnBack"
                    class="btn-back"
                    @click="gobackGenerate()"
                  >
                    <div class="icon-back">
                      <a-icon type="arrow-left" />
                    </div>
                  </div>
                </div>
              </div>

              <a-skeleton v-if="isSkeleton" active />

              <Account
                :show="showAccount"
                :data="dataAccount"
                @changeAccount="ChangeAccount($event)"
              />

              <AddMerchant
                :show="showMerchant"
                :data="dataMerchant"
                @changeMerchant="ChangeMerchant($event)"
              />

              <Generate
                :show="showGenerate"
                :data="dataGenerate"
                @changeGenerate="ChangeGenerate($event)"
              />
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Header from '@/components/Layout/Header'
import Mixin from '@/mixin/Mixin'
import Config from '@/helper/Config.js'
import Locale from '@/helper/locale.js'
import Account from '@/module/Settings/3rdParty/components/Account.vue'
import AccountHelper from '@/helper/AccountHelper.js'
import AddMerchant from '@/module/Settings/3rdParty/components/AddMerchant.vue'
import Generate from '@/module/Settings/3rdParty/components/Generate.vue'
import Breadcrumbs from '@/module/Settings/3rdParty/components/Breadcrumbs.vue'
import BzbsSettings3rdParty from '@/core/Settings/3rdParty/BzbsSettings3rdParty'

export default {
  name: 'Extensions',
  mixins: [Mixin],
  components: {
    Header,
    Account,
    AddMerchant,
    Breadcrumbs,
    Generate,
  },
  data() {
    return {
      currenttitle: 'Settings',
      current: 'Extensions',
      parent: {},
      configThirdParty: [],
      partnerId: '',
      Partner: {},
      languageLocale: 'en',
      showAccount: false,
      dataAccount: {},
      showMerchant: false,
      dataMerchant: {},
      isSkeleton: false,
      showGenerate: false,
      dataGenerate: {},
      merchantDetail: {},
      isBtnBack: false,
    }
  },
  watch: {
    lbl(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setLocale()
      }
    },
  },
  created() {
    this.handleFooter(true)
    AccountHelper.bzbsAnalyticTracking(
      'setting_api_page',
      'setting_api',
      'view_api_connect',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.getConfigThirdParty()
      this.languageLocale = Locale.getLocaleCode()
    },
    setLocale() {
      this.currenttitle = this.lbl['main-menu-setting']
      this.current = this.lbl['extensions']
      this.parent = null
    },
    setLocaleAddMerchant() {
      this.currenttitle = this.lbl['main-menu-setting']
      this.current = this.lbl['third-party-add-merchant']
      this.parent = {
        title: this.lbl['extensions'],
      }
    },
    setLocaleGenerate() {
      this.currenttitle = this.lbl['main-menu-setting']
      this.current = this.lbl['third-party-generate-api']
      this.parent = {
        title: this.lbl['extensions'],
      }
    },
    getConfigThirdParty() {
      Config.getConfigThirdParty().then(res => {
        this.genDataThirdParty(res)
      })
    },
    genDataThirdParty(data) {
      var result = _.find(data, item => {
        if (item) return item
      })
      console.log('getConfigThirdParty : ', result)
      this.configThirdParty = result

      if (this.configThirdParty.length > 0) {
        this.handlePartner(this.configThirdParty[0])
      }
    },
    handlePartner(item) {
      AccountHelper.bzbsAnalyticTracking(
        'setting_api_page',
        'setting_api',
        'click_api_connect_' + item.name,
        'on click',
      )
      var id = item.id
      this.setLocale()
      this.isSkeleton = true
      this.showAccount = false
      this.showMerchant = false
      this.showGenerate = false
      this.isBtnBack = false
      this.Partner = {}
      this.dataAccount = {}
      this.dataMerchant = {}
      this.dataGenerate = {}
      this.merchantDetail = {}
      this.partnerId = id

      this.Partner = _.find(this.configThirdParty, item => {
        if (item.id == id) return item
      })

      this.getMerchantDetail(id)
    },
    getMerchantDetail(id) {
      return new Promise(resolve => {
        BzbsSettings3rdParty.getMerchantDetail(id)
          .then(res => {
            switch (id) {
              case 'zortout':
                AccountHelper.bzbsAnalyticTracking(
                  'setting_api_page',
                  'setting_api',
                  'view_api_ZORT',
                  'on view',
                )
                break
              case 'buzzebees':
                AccountHelper.bzbsAnalyticTracking(
                  'setting_api_page',
                  'setting_api',
                  'view_api_Buzzebees',
                  'on view',
                )
                break
              case 'foodie':
                AccountHelper.bzbsAnalyticTracking(
                  'setting_api_page',
                  'setting_api',
                  'view_api_Foodie',
                  'on view',
                )
                break
              default:
                AccountHelper.bzbsAnalyticTracking(
                  'setting_api_page',
                  'setting_api',
                  'view_api_' + id,
                  'on view',
                )
                break
            }
            this.isSkeleton = false
            if (res.data.success) {
              this.genDataGenarate(res.data)
            } else {
              this.dataAccount = {
                partner: this.Partner,
              }
              this.showAccount = true
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('getMerchantDetail error', error)
            this.isSkeleton = false
            resolve(error)
          })
      })
    },
    genDataGenarate(data) {
      this.merchantDetail = data.data
      this.dataGenerate = {
        partner: this.Partner,
        generate: {
          username: data.data.username,
          password: data.data.password,
          merchants: data.data.merchants,
        },
      }
      this.showGenerate = true

      // this.setLocaleGenerate()
    },

    // Account
    ChangeAccount(res) {
      console.log('ChangeAccount : ', res)
      this.showAccount = false
      this.dataAccount = {}

      if (res != null) {
        this.setLocaleAddMerchant()
        this.showMerchant = true
        this.dataMerchant = {
          partner: this.Partner,
          account: {
            username: res.username,
            password: res.password,
          },
        }
      }
    },

    // Merchant
    ChangeMerchant(res) {
      console.log('ChangeMerchant : ', res)
      this.isBtnBack = false
      this.showMerchant = false
      this.dataMerchant = {}

      if (res == true) {
        this.getMerchantDetail(this.partnerId)
      }
    },

    // Generate
    ChangeGenerate(res) {
      console.log('ChangeGenerate : ', res)
      this.showGenerate = false
      this.dataGenerate = {}

      if (res) {
        if (res.add_merchant) {
          this.showMerchant = true
          this.dataMerchant = {
            partner: this.Partner,
            account: {
              username: this.merchantDetail.username,
              password: this.merchantDetail.password,
            },
          }
          this.isBtnBack = true
          this.setLocaleAddMerchant()
        } else if (res.re_generate_token) {
          this.getMerchantDetail(this.partnerId)
        } else if (res.update_password) {
          this.getMerchantDetail(this.partnerId)
        }
      }
    },
    gobackGenerate() {
      this.showAccount = false
      this.showMerchant = false
      this.showGenerate = false
      this.isBtnBack = false
      this.getMerchantDetail(this.partnerId)
    },
  },
}
</script>

<style lang="scss">
@import '../styles/thirdparty_main.scss';
</style>
